export const PHY_variables = [
    {id: 1, value: 'm \\overrightarrow g', cafeval: "1"},
    {id: 2, value: '\\overrightarrow N', cafeval: "2"},
    {id: 3, value: '\\overrightarrow T', cafeval: "3"},
    {id: 4, value: '\\overrightarrow F_{mot}', cafeval: "4"},
    {id: 5, value: '\\overrightarrow F_{f,s}', cafeval: "5"},
    {id: 6, value: '\\overrightarrow F_{f,c}', cafeval: "6"},
    {id: 7, value: 'm \\overrightarrow a', cafeval: "7"},
    {id: 8, value: '\\overrightarrow 0', cafeval: "8"},
];
export const PHY_operators = [
    {id: 9, value: '=', cafeval: "="},
    {id: 10, value: '+', cafeval: "+"},
    {id: 11, value: '-', cafeval: "-"},
    {id: 12, value: '\\times', cafeval: "*"},
    {id: 13, value: '/', cafeval: "/"}
];