import React, { useEffect, useRef, useState } from 'react';
import {
  AbsoluteCenter,
  Box,
  Center,
  Divider,
  Flex,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import './index.css';
import { Navbar } from './Navbar';
import Latex from 'react-latex';
import tac_sound from './resources/tac.wav';
import notif_sound from './resources/notification.mp3';
import plonk_sound from './resources/plonk.wav';
import Step1 from './steps/Step1';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';
import Step2 from './steps/Step2';
import { getFeedback, getProgress, restart, saveProgress, sendAnswerToCafe } from './API/progress';
import Step6 from './steps/Step6';
import Cookies from 'js-cookie';
import { IPhysicsReport } from './types';
import { faBug, faCircleExclamation, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Home: React.FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [waitingForFeedback, setWaitingForFeedback] = useState<boolean>(false);
  const [waitingForFeedbackLoading, setWaitingForFeedbackLoading] = useState<boolean>(false);
  const [erroneous, setErroneous] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<{
    code: string,
    msg: string,
    msgType: string,
    box_nb?: number[],
  }[][]|null>(null);
  const [progressId, setProgressId] = useState<string>('');
  const [marks, setMarks] = useState<number>(0);
  const [stepsDescription, setStepsDescription] = useState<string[]>([
    "Dessin des forces et orientations",
    "Forme vectorielle de la 2e loi de Newton",
    "Projection sur l'axe x",
    "Projection sur l'axe y",
    "Expression de la force de frottement"
  ]);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [answerStep1, setAnswerStep1] = useState<{id:number, content:string, cafeval:string}[]>([]);
  const [answerStep2, setAnswerStep2] = useState<{id:any,value:string,type:string, cafeval:string}[]>([]);
  const [answerStep3, setAnswerStep3] = useState<{id:any,value:string,type:string, cafeval:string}[]>([]);
  const [answerStep4, setAnswerStep4] = useState<{id:any,value:string,type:string, cafeval:string}[]>([]);
  const [answerStep5, setAnswerStep5] = useState<{id:any,value:string,type:string, cafeval:string}[][]>([[]]);

  useEffect(() => {
    getProgress().then((res) => {
      setAnswerStep1(res.data.blankModel);
      setAnswerStep2(res.data.vectEquations);
      setAnswerStep3(res.data.projectionsX);
      setAnswerStep4(res.data.projectionsY);
      setAnswerStep5(res.data.finalEquations);
      setSubmitted(res.data.submitted);
      if (res.data.submitted) {
        pingFeedback();
        if (res.data.feedback_id.length == 0) {
          setWaitingForFeedback(true);
        } else {
          setWaitingForFeedbackLoading(true);
        }
      }
      setProgressId(res.data._id);
    }).catch((err) => {
    }).finally(() => {
      setPageLoading(false);
    });

    // Get current step
    const currentStep = Cookies.get('currentStep');
    if (currentStep) {
      setCurrentStep(parseInt(currentStep));
    }
  }, []);

  const previous_step = () => {
    if (currentStep === 1) return;
    setCurrentStep(currentStep - 1);
    Cookies.set('currentStep', (currentStep - 1).toString());

    // Play tac sound
    const audio = new Audio(tac_sound);
    audio.play();
  }

  const next_step = () => {
    if (currentStep === 6) return;
    setCurrentStep(currentStep + 1);
    Cookies.set('currentStep', (currentStep + 1).toString());

    // Play plonk sound
    const audio = new Audio(plonk_sound);
    audio.play();
  }

  const updateAnswerStep1 = (newAnswer: any[]) => {
    setAnswerStep1(newAnswer);
    const answer = {
      blankModel: newAnswer,
      vectEquations: answerStep2,
      projectionsX: answerStep3,
      projectionsY: answerStep4,
      finalEquations: answerStep5
    }
    saveProgress(answer).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la sauvegarde de votre réponse: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  }

  const updateAnswerStep2 = (newAnswer: any[]) => {
    setAnswerStep2(newAnswer);
    const answer = {
      blankModel: answerStep1,
      vectEquations: newAnswer,
      projectionsX: answerStep3,
      projectionsY: answerStep4,
      finalEquations: answerStep5
    }
    saveProgress(answer).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la sauvegarde de votre réponse: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  }

  const updateAnswerStep3 = (newAnswer: any[]) => {
    setAnswerStep3(newAnswer);
    const answer = {
      blankModel: answerStep1,
      vectEquations: answerStep2,
      projectionsX: newAnswer,
      projectionsY: answerStep4,
      finalEquations: answerStep5
    }
    saveProgress(answer).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la sauvegarde de votre réponse: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  }

  const updateAnswerStep4 = (newAnswer: any[]) => {
    setAnswerStep4(newAnswer);
    const answer = {
      blankModel: answerStep1,
      vectEquations: answerStep2,
      projectionsX: answerStep3,
      projectionsY: newAnswer,
      finalEquations: answerStep5
    }
    saveProgress(answer).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la sauvegarde de votre réponse: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  }

  const updateAnswerStep5 = (newAnswer: any[][]) => {
    setAnswerStep5(newAnswer);
    const answer = {
      blankModel: answerStep1,
      vectEquations: answerStep2,
      projectionsX: answerStep3,
      projectionsY: answerStep4,
      finalEquations: newAnswer
    }
    saveProgress(answer).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la sauvegarde de votre réponse: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    });
  }

  const intervalRef = useRef<NodeJS.Timeout | null>(null); // Ref to store the interval ID
  const correct = () => {
    sendAnswerToCafe(progressId).catch((err) => {
      toast({
        title: "Erreur",
        description: "Une erreur s'est produite lors de la correction de votre réponse: " + err.response.data.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } );

    setSubmitted(true);
    setWaitingForFeedback(true);
    pingFeedback();
  };

  const pingFeedback = () => {
    // Call get_feedback every second (1000ms)
    intervalRef.current = setInterval(() => {
      getFeedback().then((res) => {
        const report: IPhysicsReport = res.data;
        if (!report.erroneous && report.feedback.length > 0) {
          setFeedback(JSON.parse(report.feedback).Output.SP1);
          setMarks(JSON.parse(report.feedback).Marks)
          setWaitingForFeedback(false);
          setWaitingForFeedbackLoading(false);

          if (waitingForFeedback) {
            // play notif_sound
            const audio = new Audio(notif_sound);
            audio.play();
          }
  
          // Clear the interval once feedback is received
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null; // Reset the ref to null after clearing
          }
        } else {
          if (report.erroneous) {
            // error mode
            setErroneous(true);
          } else {
            // If no feedback, keep waiting
            setWaitingForFeedback(true);
          }
          return;
        }
      }).catch((err) => {
      });
    }, 1000);
  }

  const restartExercise = () => {
    restart().then((resp) => {
      // Set cookie
      Cookies.set('currentStep', '1');
      // Reset the state
      window.location.reload();
    }).catch((err) => {});
  }
  
  return (<>
    <Navbar onOpen={onOpen} />
    <Center>
      {pageLoading &&
      <Box minH="100vh" bg={"#131f24"} width={'850px'}>
        <Center mt={'300px'}>
          <Spinner
            thickness='6px'
            speed='0.65s'
            emptyColor='gray.500'
            color='white'
            size='xl'
          />
        </Center>
        <Center>
          <Text fontWeight={700} fontSize={'xl'}>Chargement...</Text>
        </Center>
      </Box>
      }
      {!pageLoading &&
      <Box minH="100vh" bg={"#131f24"} width={'850px'}>

        <Box height={'30px'} />
        
        <Center ms={2} mb={2} textAlign={'left'}>
          <Box width={'100%'}>
            <Text fontSize={'3xl'} color={'#52656D'} fontWeight={800}>Énoncé</Text>
          </Box>
        </Center>

        <Center>
          {/* <Welcome /> */}

          <Box border={'4px solid #37464F'} p={5} rounded={'xl'} width={'850px'} textAlign={'justify'}>
              <Text fontWeight={600} fontSize={'xl'} cursor={'default'}>
                Un bloc de masse <span style={{color:'#FFB734'}}><Latex>m</Latex></span> est posé sur un plan incliné d'un angle <span style={{color:'#FFB734'}}><Latex>$\theta$</Latex></span> par rapport à l'horizontale. 
                Détermine l'expression de <span style={{color:'#FFB734', fontWeight:700}}>la force de frottement</span>.
              </Text>
          </Box>
        </Center>

        {currentStep === 1 && <Step1 submitted={submitted} answerStep1={answerStep1} updateAnswerStep1={updateAnswerStep1} />}
        {currentStep === 2 && <Step2 submitted={submitted} updateAnswerStep2={updateAnswerStep2} answerStep1={answerStep1} answerStep2={answerStep2} />}
        {currentStep === 3 && <Step3 submitted={submitted} updateAnswerStep3={updateAnswerStep3} answerStep1={answerStep1} answerStep2={answerStep2} answerStep3={answerStep3} />}
        {currentStep === 4 && <Step4 submitted={submitted} answerStep1={answerStep1} answerStep2={answerStep2} updateAnswerStep4={updateAnswerStep4} answerStep4={answerStep4} />}
        {currentStep === 5 && <Step5 submitted={submitted} answerStep1={answerStep1} answerStep2={answerStep2} answerStep3={answerStep3} answerStep4={answerStep4} updateAnswerStep5={updateAnswerStep5} answerStep5={answerStep5} />}
        {currentStep === 6 && <Step6 submitted={submitted} answerStep1={answerStep1} answerStep2={answerStep2} answerStep3={answerStep3} answerStep4={answerStep4} answerStep5={answerStep5} />}

        <Divider borderColor={'#52656D'} borderWidth={'2px'} borderRadius={'90'} mt={5} mb={2} />

        {submitted &&
          <Box bgColor={"#394850"} p={4} textAlign={'center'} fontWeight={700} rounded={'md'}>
            Cet exercice a déjà été soumis et ne peut être modifié.
          </Box>
        }

        <Flex mt={2}>
          {currentStep > 1 && <>
          <Box
          onClick={previous_step}
          cursor={'pointer'}
          border={'2pt solid #52656D'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#52656D'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              RETOUR
          </Box>
          </>}
          <Spacer />

          {currentStep < 5 && <>
          <Box
            cursor={'pointer'}
            onClick={next_step}
            border={'2pt solid #FFB734'} 
            p={3}
            mt={3}
            className="hvr-grow"
            bgColor={'#FFB734'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                {'ÉTAPE SUIVANTE'}
          </Box>
          </>}

          {currentStep == 5 && <>
          <Box
            cursor={'pointer'}
            onClick={next_step}
            border={'2pt solid #D46769'} 
            p={3}
            mt={3}
            className="hvr-grow"
            bgColor={'#D46769'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                {'TERMINER'}
          </Box>
          </>}

          {!submitted && currentStep == 6 && <>
          <Box
            cursor={'pointer'}
            onClick={correct}
            border={'2pt solid #D46769'} 
            p={3}
            mt={3}
            className="hvr-grow"
            bgColor={'#D46769'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                {'CORRIGER MA RÉPONSE'}
          </Box>
          </>}
        </Flex>

        {submitted && waitingForFeedback &&
        <Box mt={4}>
          <Center>
            <Spinner
              mb={2}
              thickness='6px'
              speed='0.65s'
              emptyColor='gray.500'
              color='white'
              size='xl'
            />
          </Center>
          <Center>
            <Text fontWeight={700} fontSize={'xl'}>Correction en cours...</Text>
          </Center>
        </Box>}

        {submitted && !waitingForFeedback && waitingForFeedbackLoading && !erroneous &&
        <Box mt={4}>
          <Center>
            <Spinner
              mb={2}
              thickness='6px'
              speed='0.65s'
              emptyColor='gray.500'
              color='white'
              size='xl'
            />
          </Center>
          <Center>
            <Text fontWeight={700} fontSize={'xl'}>Récupération du feedback...</Text>
          </Center>
        </Box>
        }

        {submitted && erroneous && 
        <Box mt={5} border={'2pt solid #E53E3E'} rounded={'lg'} p={5}>
          <Center mb={3}>
            <FontAwesomeIcon color='#E53E3E' size='lg' icon={faBug} />
          </Center>
          <Center>
            <Text fontWeight={700} color={'red.500'} fontSize={'xl'}>Une erreur est survenue lors de la correction de cet exercice. L'exercice sera déverrouillé lorsque tu rechargeras la page, mais tu peux contacter l'équipe éducative pour leur signaler le problème.</Text>
          </Center>
        </Box>
        }
        
        {feedback &&
          <Divider borderColor={'#52656D'} borderWidth={'2px'} borderRadius={'90'} mt={10} mb={10} />
        }

        {feedback &&
          <Box border={'4px solid #37464F'} p={5} rounded={'xl'} width={'850px'} textAlign={'justify'} mt={4}>
            <Text fontWeight={800} color={'#FFB734'} fontSize={'3xl'}>Feedback automatique</Text>
            
            <Divider borderColor={'#52656D'} borderWidth={'2px'} borderRadius={'90'} mt={4} mb={2} />

            {stepsDescription.map((step, index) => (
              <Box key={index} p={2}>
                <Text fontSize={'2xl'} color={'#52656D'} fontWeight={700}>{step}</Text>
                {index == 1 && <Box ms={2}><span style={{fontWeight:700, marginRight:"5px"}}>Réponse :</span>
                  <Latex>{'$'+answerStep2.map((answer) => answer.value).join('')+'$'}</Latex>
                  {answerStep2.length == 0 && <Text>Aucune réponse</Text>}
                </Box>}
                {index == 2 && <Box ms={2}><span style={{fontWeight:700, marginRight:"5px"}}>Réponse :</span>
                  <Latex>{'$'+answerStep3.map((answer) => answer.value).join('')+'$'}</Latex>
                  {answerStep3.length == 0 && <Text>Aucune réponse</Text>}
                </Box>}
                {index == 3 && <Box ms={2}><span style={{fontWeight:700, marginRight:"5px"}}>Réponse :</span>
                  <Latex>{'$'+answerStep4.map((answer) => answer.value).join('')+'$'}</Latex>
                  {answerStep4.length == 0 && <Text>Aucune réponse</Text>}
                </Box>}
                {index == 4 && <Box ms={2}><span style={{fontWeight:700, marginRight:"5px"}}>Réponse :</span>
                  {answerStep5.length > 0 && answerStep5.every((line) => line.length == 0) && <Text>Aucune réponse</Text>}
                  {!(answerStep5.length > 0 && answerStep5.every((line) => line.length == 0)) && answerStep5.map((line, index) => (
                    <Box key={index}>{line.length == 0 ? 'Ligne vide' :<Latex>{'$'+line.map((answer) => answer.value).join('')+'$'}</Latex>}</Box>
                  ))}
                  {answerStep5.length == 0 && <Text>Aucune réponse</Text>}
                </Box>}
                <Box border={'2px solid #37464F'} ms={2} mt={2} p={4} ps={5} pe={5} bgColor={'white'} rounded={'xl'} width={'100%'} textAlign={'justify'}>
                  {feedback[index] && feedback[index].map((line, index) => (
                    line.msgType === 'TITLE' && <Text key={index} fontWeight={700} fontSize={'lg'} color={"black"}>{(line.box_nb && line.box_nb.length > 0) ? "[Boite "+line.box_nb?.join(', ')+"]" : ""} {line.msg} :</Text> ||
                    line.msgType === 'INFO' && <Text key={index} color={'green.500'} fontWeight={600}><FontAwesomeIcon icon={faSquareCheck} /> {(line.box_nb && line.box_nb.length > 0) ? "[Boite "+line.box_nb?.join(', ')+"]" : ""} {line.msg}</Text> ||
                    line.msgType === 'ERROR' && <Text key={index} color={'red.500'} fontWeight={600}><FontAwesomeIcon icon={faCircleExclamation} /> {(line.box_nb && line.box_nb.length > 0) ? "[Boite "+line.box_nb?.join(', ')+"]" : ""} {line.msg}</Text>
                  ))}
                  {!  feedback[index] && <Text fontWeight={700} fontSize={'lg'} color={"black"}>Aucun feedback</Text>}
                </Box>
                {index < stepsDescription.length - 1 &&
                  <Divider borderColor={'#52656D'} borderWidth={'1px'} borderRadius={'90'} mt={5} />
                }
              </Box>
            ))}
            <Flex>
              <Spacer />
              <Text fontSize={'xl'} color={"#FFB734"} fontWeight={700} mt={2} me={1}>Cote finale : {marks}/20</Text>
            </Flex>
          </Box>
        }

        {feedback && 
        <Flex>
          <Spacer />
          <Box
            cursor={'pointer'}
            onClick={restartExercise}
            border={'2pt solid #77A374'} 
            p={3}
            mt={3}
            className="hvr-grow"
            bgColor={'#77A374'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                {"RECOMMENCER L'EXERCICE"}
          </Box>
        </Flex>}

      </Box>
      }
    </Center>
    <Box height={'250px'} />
  </>);
}
export default Home;