import React, { useEffect, useState } from 'react';
import {
  AbsoluteCenter,
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { LoremIpsum } from 'react-lorem-ipsum';
import { loremIpsum } from 'react-lorem-ipsum';
import Latex from 'react-latex';
import box_pic from '../resources/Box.svg';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';
import tac_sound from '../resources/tac.wav';

interface Step1Props {
  submitted: boolean;
  answerStep1: {id:number, content:string, cafeval:string}[];
  updateAnswerStep1: (newAnswer: {id:number, content:string, cafeval:string}[]) => void;
}

const Step1: React.FunctionComponent<Step1Props> = ({ submitted, answerStep1, updateAnswerStep1 }) => {
    const variables = [
        {id: 1, value: 'm \\overrightarrow g', cafeval: "1"},
        {id: 2, value: '\\overrightarrow N', cafeval: "2"},
        {id: 3, value: '\\overrightarrow T', cafeval: "3"},
        {id: 4, value: '\\overrightarrow F_{mot}', cafeval: "4"},
        {id: 5, value: '\\overrightarrow F_{f,s}', cafeval: "5"},
        {id: 6, value: '\\overrightarrow F_{f,c}', cafeval: "6"},
        {id: 7, value: 'm \\overrightarrow a', cafeval: "7"},
        // {id: 8, value: '\\overrightarrow 0', cafeval: "8"},
        // {id: 9, value: '\\overrightarrow m', cafeval: "9"},
    ];
    const [answerBoxes, setAnswerBoxes] = useState([
          {id: 1, value: '', offsetY: -15, offsetX: 55, offsetBottom: 0, offsetRight: 0},
          {id: 8, value: '', offsetY: -12, offsetX: 43, offsetBottom: 0, offsetRight: 0},
          {id: 7, value: '', offsetY: 35, offsetX: 29, offsetBottom: 0, offsetRight: 0},
          {id: 6, value: '', offsetY: 53, offsetX: 30, offsetBottom: 0, offsetRight: 0},
          {id: 5, value: '', offsetY: 86, offsetX: 55, offsetBottom: 0, offsetRight: 0},
          {id: 4, value: '', offsetY: 83, offsetX: 66.5, offsetBottom: 0, offsetRight: 0},
          {id: 3, value: '', offsetY: 37, offsetX: 80.5, offsetBottom: 0, offsetRight: 0},
          {id: 2, value: '', offsetY: 21, offsetX: 79, offsetBottom: 0, offsetRight: 0},
    ]);

    useEffect(() => {
      // Update the answer boxes with the parent component's answer
      let newAnswerBoxes = [...answerBoxes];
      for (let i = 0; i < answerStep1.length; i++) {
        //find the index of the answer box with the same id
        let index = newAnswerBoxes.findIndex((box) => box.id === answerStep1[i].id);
        newAnswerBoxes[index].value = answerStep1[i].content;
      }
      setAnswerBoxes(newAnswerBoxes);
    }, [answerStep1]);
  
    function Draggable(props:any) {
        const {attributes, listeners, setNodeRef, transform} = useDraggable({
          id: props.id
        });
        const style = transform ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        } : undefined;
      
        
        return (
          <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {props.children}
          </div>
        );
    }
  
    function Droppable(props:any) {
        const {isOver, setNodeRef} = useDroppable({
          id: props.id,
        });
        
        return (
          <Box 
            onClick={() => onClick(props.id)}
            bgColor={isOver ? '#29434D' : '#131F24'}
            display={'flex'}
            alignItems={'center'}
            border={'2px solid #37464F'}
            justifyContent={'center'}
            cursor={'pointer'}
            className='hvr-grow' 
            height={'42px'}
            color={'white'} 
            width={'70px'} 
            pt={1} 
            textAlign={'center'} 
            rounded={'lg'}
            ref={setNodeRef}
            position={'absolute'}
            top={props.style.top}
            left={props.style.left}
            >
              {props.children}
            </Box>
        );
    }
  
    function onDragEnd(event:any) {
        if (submitted)
          return;
        const {active, over} = event;
        if (!over || !active) {
          return;
        }
  
        // Update the answer box
        let newAnswerBoxes = [...answerBoxes];
        let index = newAnswerBoxes.findIndex((box) => box.id === over.id);
        // Get the value of the dragged element
        let draggedValue = variables.find((variable) => variable.id === active.id)?.value;
        newAnswerBoxes[index].value = draggedValue || '';
        setAnswerBoxes(newAnswerBoxes);

        // Update the parent component
        let newAnswer = newAnswerBoxes.map((box) => ({id:box.id, content:box.value, cafeval:box.value.length > 0 ? variables.find((variable) => variable.value == box.value)!.cafeval : ""}));
        updateAnswerStep1(newAnswer);
  
        // Play a sound
        const audio = new Audio(tac_sound);
        audio.play();
    }
  
    function onClick(clickedId:number) {
        if (submitted)
          return;
        // Delete the value of the clicked answer box
        let newAnswerBoxes = [...answerBoxes];
        let index = newAnswerBoxes.findIndex((box) => box.id === clickedId);
        // If the value is already empty, do nothing
        if (newAnswerBoxes[index].value === '') return;
  
        newAnswerBoxes[index].value = '';
        setAnswerBoxes(newAnswerBoxes);

        // Update the parent component
        let newAnswer = newAnswerBoxes.map((box) => ({id:box.id, content:box.value, cafeval:box.value.length > 0 ? variables.find((variable) => variable.id === box.id)!.cafeval : ''}));
        updateAnswerStep1(newAnswer);
  
        // Play a sound
        const audio = new Audio(tac_sound);
        audio.playbackRate = 3;
        audio.play();
    }
    return <DndContext onDragEnd={onDragEnd}>
    <Center mt={5}>
      <Box position={'relative'} fontSize={'lg'} sx={{ userSelect: 'none' }}>
        <Box height={'40px'} />

        {/* Inclined plane */}
        <Box height={'300px'} width={'100%'} position={'relative'}>
          <Image draggable={false} cursor={'default'} src={box_pic} alt="box" height={'300px'} />
          {/* Forces */}
          {answerBoxes.map((answerBox, index) => (
            <Droppable 
              key={index} 
              id={answerBox.id}
              style={{
                top: answerBox.offsetY+'%',
                left: answerBox.offsetX+'%',
                bottom: answerBox.offsetBottom+'%',
                right: answerBox.offsetRight+'%',
              }}
              position={'relative'}
            >
              {[8,7,6,5].includes(answerBoxes.length - index + 1) && 
                <Text cursor={'default'} position={'absolute'} left={'-15px'} fontSize={'xs'} fontWeight={700}>{(answerBoxes.length - index)+1}</Text>
              }
              {[1,3,4].includes(answerBoxes.length - index + 1) && 
                <Text cursor={'default'} position={'absolute'} right={'-15px'} fontSize={'xs'} fontWeight={700}>{(answerBoxes.length - index)+1}</Text>
              }
              {(answerBoxes.length - index + 1) == 2 &&
                <Text cursor={'default'} position={'absolute'} top={'-20px'} fontSize={'xs'} fontWeight={700}>{(answerBoxes.length - index)+1}</Text>
              }
              {index == 0 &&
                <Text cursor={'default'} position={'absolute'} right={'-15px'} fontSize={'xs'} fontWeight={700}>{1}</Text>
              }
              <Latex>{'$'+answerBox.value+'$'}</Latex>
            </Droppable>
          ))}
        </Box>

        {/* Angles */}
        <Box position={'absolute'} fontSize={'2xl'} bottom={'22px'} left={'20px'} color={'white'}>
          <Latex>{'$\\Phi$'}</Latex>
        </Box>
        <Box position={'absolute'} fontSize={'2xl'} bottom={'5px'} left={'100px'} color={'white'}>
          <Latex>{'$\\theta$'}</Latex>
        </Box>

        <Box position={'absolute'} fontSize={'lg'} top={'101px'} right={'49px'} color={'#485842'}>
          <Latex>{'$x$'}</Latex>
        </Box>
        <Box position={'absolute'} fontSize={'lg'} top={'45px'} right={'23px'} color={'#485842'}>
          <Latex>{'$y$'}</Latex>
        </Box>
      </Box>
    </Center>

    <Divider borderColor={'#52656D'} borderWidth={'2px'} borderRadius={'90'} mt={5} mb={2} />

    <Box mt={4} textAlign={'justify'}>
      <Text fontSize={'2xl'} color={'#52656D'} fontWeight={800} mb={2}>Étape 1 : Forces et orientation</Text>
      <Text fontSize={'md'} color={'white'} fontWeight={600}>Afin de résoudre cet exercice, commençons par illustrer les forces en jeu. 
        Glisse les forces s'appliquant sur la caisse (selon les bonnes directions et le bon sens) dans les cases. Si une case doit rester vide, alors ne glisse rien dedans. Pour supprimer la valeur d'une case, clique dessus.</Text>
    </Box>
    
    <Center mt={5}>
        <HStack spacing={2}>
          {variables.map((variable, index) => (
            <Draggable key={index} id={variable.id}>
              <Box 
                sx={{ userSelect: 'none' }}
                bgColor={'#131F24'}
                className='hvr-grow'
                cursor={'grab'}
                onMouseDown={(e) => e.currentTarget.style.cursor = 'grabbing'}
                onMouseUp={(e) => e.currentTarget.style.cursor = 'grab'}
                border={'2px solid #37464F'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                color={'white'} width={'70px'} height={'42px'} p={2} pt={1} pb={1} textAlign={'center'} rounded={'lg'}>
                <Latex>{'$'+variable.value+'$'}</Latex>
              </Box>
            </Draggable>
          ))}
        </HStack>
    </Center>
  </DndContext>
}

export default Step1;