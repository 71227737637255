import React, { useEffect, useState } from 'react';
import { AbsoluteCenter, Spinner } from '@chakra-ui/react';
import { useUser } from './UserContext';
import Cookies from 'js-cookie';
import { getUserData } from './API/progress';
// import { getUserData } from './API/userData';

const SetUser: React.FC = () => {
    const { user, setUser } = useUser();

    function parseJwt(token:string) {
        try {
            // Split the token into its parts
            const base64Url = token.split('.')[1];
            // Replace invalid characters and decode from base64
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
    
            return JSON.parse(jsonPayload);
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        // Read the token from the jwt_cafe cookie
        const token = Cookies.get('jwt_cafe');
        if (token) {
            getUserData().then((resp) => {
                const parsed_token = parseJwt(token);
                if (parsed_token) {
                    // Create account in DB and then set user.
                    setUser({
                        rank: parsed_token.rank,
                        matricule: parsed_token.matricule,
                        firstname: parsed_token.firstname,
                        lastname: parsed_token.lastname
                    });
                }
            });
        }
    }, []);

    return (<>
    
        <AbsoluteCenter mt={"300px"}>
            <Spinner
                thickness='6px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
                size='xl'
            />
        </AbsoluteCenter>
    </>);
}

export default SetUser;