import {
    IconButton,
    Avatar,
    Box,
    Flex,
    HStack,
    VStack,
    Text,
    FlexProps,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Image,
    Skeleton,
    Tooltip,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    useDisclosure,
  } from '@chakra-ui/react';
  import {
    FiMenu,
    FiChevronDown,
  } from 'react-icons/fi';
  import { faCoffee, faGears, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { useEffect, useState } from 'react';
import { useUser } from './UserContext';
import Cookies from 'js-cookie';
  
  interface MobileProps extends FlexProps {
      onOpen: () => void;
      full?: boolean;
  }
  export const Navbar = ({ onOpen, full=false, ...rest }: MobileProps) => {
      const [loaded, setLoaded] = useState(true);
      const { user } = useUser();
      const modal_disclosure = useDisclosure();

      const logout = () => {
        Cookies.remove('jwt_cafe');
        window.location.href = '/';
      }
  
      return (
        <Flex
          ml={{ base: 0, md: 0 }}
          px={{ base: 4, md: 4 }}
          height="20"
          alignItems="center"
          bg={"#131f24"}
          color={"white"}
          borderBottomWidth="3px"
          borderBottomColor={"#37464f"}
          justifyContent={{ base: 'space-between', md: 'flex-end' }}
          {...rest}>
            <Modal onClose={modal_disclosure.onClose} size={'3xl'} isOpen={modal_disclosure.isOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader color={'black'} fontWeight={800}>À propos...</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text color={'black'} fontWeight={800} fontSize={'lg'} mb={2}>CAFÉ 2.0</Text>
                  <Text color={'black'}fontWeight={500} textAlign={'justify'} >CAFÉ signifie "Correction Automatique & Feedback des Étudiants". C'est un projet de recherche dirigé par <a target='_blank' style={{fontWeight:700, color:"#395276"}} href='https://www.uliege.be/cms/c_9054334/fr/repertoire?uid=U216357'>Pr. Benoit Donnet</a>. Le but de ce projet est de mener une étude en développant une plateforme de correction automatique d'exercices de programmation et d'algorithmique, et d'autres cours (notamment ce cours de physique), ainsi qu'une expérience personnalisée d'apprentissage, et de fournir des feedbacks automatiques aux étudiant·e·s.</Text>
                  <Text color={'black'} fontWeight={800} fontSize={'lg'} mb={2} mt={2}>Physics Engine</Text>
                  <Text color={'black'}fontWeight={500} textAlign={'justify'} >Ce site est un prototype d'exercice de physique associé à un moteur de correction automatique. Le but de cette application est de réaliser une première tentative de correction automatique sur un exercice différent de ceux dont le moteur de CAFÉ s'occupe actuellement. Toutes les données collectées par ce site web sont utilisées à des fins de recherche uniquement.</Text>
                  <Text color={'black'}fontWeight={500} fontSize={'sm'} textAlign={'justify'} mt={4} >Info : Pour des questions relatives à la correction automatique, contactez <a target='_blank' style={{fontWeight:700, color:"#395276"}} href='https://www.uliege.be/cms/c_9054334/fr/repertoire?uid=u237531'>Géraldine Brieven</a> (Assistante - gbrieven@uliege.be). Pour des questions relatives au site web, contactez <a target='_blank' style={{fontWeight:700, color:"#395276"}} href='https://www.uliege.be/cms/c_9054334/fr/repertoire?uid=u232357'>Lev Malcev</a> (Assistant - lev.malcev@uliege.be). Pour plus d'informations concernant le projet CAFÉ 2.0, contactez <a target='_blank' style={{fontWeight:700, color:"#395276"}} href='https://www.uliege.be/cms/c_9054334/fr/repertoire?uid=U216357'>Pr. Benoit Donnet</a> (benoit.donnet@uliege.be).</Text>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={modal_disclosure.onClose}>Fermer</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
    
          <Text
            fontSize={'2xl'}
            color={"#FFB734"}
            cursor={'default'}
            fontWeight={900}>
            CAFÉ - Physics Engine
          </Text>

          <Spacer />
    
          <HStack spacing={6}>
            <Flex alignItems={'center'}>
              <Text me={4} fontWeight={700} color={'#52656D'} cursor={'pointer'} onClick={modal_disclosure.onOpen}>À propos</Text>
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none' }}>
                  <HStack>
                    {/* <Avatar
                      size={'sm'}
                      src={'https://avatars.githubusercontent.com/u/72021812?v=4'}
                    ></Avatar> */}
                    <VStack
                      display={{ base: 'none', md: 'flex' }}
                      alignItems="flex-start"
                      spacing="1px"
                      ml="2">
                      <Text fontSize="sm" fontWeight={800}>{user?.firstname} {user?.lastname}</Text>
                      <Text fontSize="xs" color="gray.600" fontWeight={700}>
                        {user?.rank == 'admin' ? "Professeur·e" : "Étudiant·e"}
                      </Text>
                    </VStack>
                    <Box display={{ base: 'none', md: 'flex' }}>
                      <FiChevronDown />
                    </Box>
                  </HStack>
                </MenuButton>
                <MenuList
                  zIndex={101}
                  bg={"#131f24"}
                  borderColor={"#37464f"}
                  borderWidth={'3px'}
                  color={"white"}>
                  <MenuItem onClick={() => {
                    window.location.href = "https://cafe.uliege.be";
                  }} bg={"#131f24"} icon={<FontAwesomeIcon icon={faCoffee} />}>Retour sur CAFÉ</MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={logout} bg={"#131f24"} icon={<FontAwesomeIcon icon={faRightFromBracket} />}>Déconnexion</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </HStack>
        </Flex>
      );
    };