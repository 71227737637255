import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import Latex from 'react-latex';
import box_pic from '../resources/Box.svg';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';
import tac_sound from '../resources/tac.wav';

interface Step4Props {
    submitted: boolean;
    answerStep1: {id:number, content:string}[];
    answerStep2: {id:number,value:string,type:string,cafeval:string}[];
    answerStep4: {id:number,value:string,type:string,cafeval:string}[];
    updateAnswerStep4 : (newAnswer: {id:number, value:string, type:string,cafeval:string}[]) => void;
}

const Step4: React.FunctionComponent<Step4Props> = ({submitted, answerStep1, answerStep2, answerStep4, updateAnswerStep4}) => {
    const variables = [
        {id: 'var-1', value: 'mg', cafeval: "1"},
        {id: 'var-2', value: 'N', cafeval: "2"},
        {id: 'var-3', value: 'T', cafeval: "3"},
        {id: 'var-4', value: 'F_{mot}', cafeval: "4"},
        {id: 'var-5', value: 'F_{f,s}', cafeval: "5"},
        {id: 'var-6', value: 'F_{f,c}', cafeval: "6"},
        {id: 'var-7', value: 'ma', cafeval: "7"},
        {id: 'var-8', value: '0', cafeval: "8"},
    ];

    const operators = [
        {id: 'op-1', value: '=', cafeval: "="},
        {id: 'op-2', value: '+', cafeval: "+"},
        {id: 'op-3', value: '-', cafeval: "-"},
        {id: 'op-4', value: '\\times', cafeval: "*"},
        {id: 'op-5', value: '/', cafeval: "/"}
    ];

    const functions = [
        {id: 'func-1', value: '\\cos', cafeval: "1"},
        {id: 'func-2', value: '\\sin', cafeval: "2"},
        {id: 'func-3', value: '\\tan', cafeval: "3"},
    ];

    const constants = [
        {id: 'const-1', value: '\\theta', cafeval: "1"},
        {id: 'const-2', value: '\\Phi', cafeval: "2"},
        {id: 'const-3', value: '0\\degree', cafeval: "3"},
        {id: 'const-4', value: '90\\degree', cafeval: "4"},
        {id: 'const-5', value: '180\\degree', cafeval: "5"},
    ];

    const [pictureBoxes, setPictureBoxes] = useState([
        {id: 1, value: '', offsetY: -15, offsetX: 55, offsetBottom: 0, offsetRight: 0},
        {id: 8, value: '', offsetY: -12, offsetX: 43, offsetBottom: 0, offsetRight: 0},
        {id: 7, value: '', offsetY: 35, offsetX: 29, offsetBottom: 0, offsetRight: 0},
        {id: 6, value: '', offsetY: 53, offsetX: 30, offsetBottom: 0, offsetRight: 0},
        {id: 5, value: '', offsetY: 86, offsetX: 55, offsetBottom: 0, offsetRight: 0},
        {id: 4, value: '', offsetY: 83, offsetX: 66.5, offsetBottom: 0, offsetRight: 0},
        {id: 3, value: '', offsetY: 37, offsetX: 80.5, offsetBottom: 0, offsetRight: 0},
        {id: 2, value: '', offsetY: 21, offsetX: 79, offsetBottom: 0, offsetRight: 0},
    ]);

    const [answerBoxes, setAnswerBoxes] = useState<{id:number, value:string, type:string, cafeval:string}[]>([]);

    useEffect(() => {
        // Set the pictureBoxes values to the answer step 1
        let newPictureBoxes = [...pictureBoxes];
        answerStep1.forEach((value) => {
            //find the index of the answer box with the same id
            let i = newPictureBoxes.findIndex((box) => box.id === value.id);
            newPictureBoxes[i].value = value.content;
        });
        setPictureBoxes(newPictureBoxes);

        // Update the answer boxes with the parent component's answer
        setAnswerBoxes(answerStep4);
    }, []);

    function Draggable(props:any) {
        const {attributes, listeners, setNodeRef, transform} = useDraggable({
          id: props.id
        });
        const style = transform ? {
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        } : undefined;
      
        
        return (
          <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
            {props.children}
          </div>
        );
    }

    function onDragEnd(event:any) {
        if (submitted)
          return;
        const {active, over} = event;
        if (!over || !active) {
          return;
        }

        // If the over element is the answer box,
        // update answerBoxes with the new value at the end of the list

        const overId = over.id;
        const activeId = active.id;
        // Get the right value and ID from the active element using the variables and operators lists
        let activeValue = '';
        let activeType = '';
        let activeCafeval = '';
        if (variables.find((variable) => variable.id == activeId)) {
            activeValue = variables.find((variable) => variable.id == activeId)!.value;
            activeType = 'givenExpr';
            activeCafeval = variables.find((variable) => variable.id == activeId)!.cafeval;
        } else if (operators.find((variable) => variable.id == activeId)) {
            activeValue = operators.find((variable) => variable.id == activeId)!.value;
            activeType = 'op';
            activeCafeval = operators.find((variable) => variable.id == activeId)!.cafeval;
        } else if (functions.find((variable) => variable.id == activeId)) {
            activeValue = functions.find((variable) => variable.id == activeId)!.value;
            activeType = 'fct';
            activeCafeval = functions.find((variable) => variable.id == activeId)!.cafeval;
        } else if (constants.find((variable) => variable.id == activeId)) {
            activeValue = constants.find((variable) => variable.id == activeId)!.value;
            activeType = 'angle';
            activeCafeval = constants.find((variable) => variable.id == activeId)!.cafeval;
        }

        if (overId == 'answer-box') {
            // Add the active element to the end of answerBoxes list
            let newAnswerBoxes = [...answerBoxes];
            newAnswerBoxes.push({id: newAnswerBoxes.length+1, value: activeValue, type: activeType, cafeval: activeCafeval});
            setAnswerBoxes(newAnswerBoxes);

            // Update the answer step 4
            updateAnswerStep4(newAnswerBoxes);

            // Play a sound
            const audio = new Audio(tac_sound);
            audio.playbackRate = 3;
            audio.play();
        }
    }

    function deleteAnswerBox(index:number) {
        if (submitted)
          return;
        let newAnswerBoxes = [...answerBoxes];
        newAnswerBoxes.splice(index, 1);
        setAnswerBoxes(newAnswerBoxes);

        // Update the answer step 4
        updateAnswerStep4(newAnswerBoxes);

        // Play a sound
        const audio = new Audio(tac_sound);
        audio.play();
    }

    function Droppable(props:any) {
        return (
        <Box 
            bgColor={'#131F24'}
            display={'flex'}
            alignItems={'center'}
            border={'2px solid #37464F'}
            justifyContent={'center'}
            height={'42px'}
            color={'white'} 
            width={'70px'} 
            pt={1} 
            textAlign={'center'} 
            rounded={'lg'}
            position={'absolute'}
            top={props.style.top}
            left={props.style.left}
            >
            {props.children}
            </Box>
        );
    }

    function AnswerBox(props:any) {
        const {isOver, setNodeRef} = useDroppable({
          id: props.id,
        });
        
        return (
            <Box
            mt={5}
            ps={2}
            border={'3px solid #475B67'}
            bgColor={'#1E343C'}
            rounded={'lg'}
            width={'100%'}
            height={'70px'}
            ref={setNodeRef}
        >
            {props.children}
        </Box>
        );
    }

    function borderColorByType(type:string) {
        switch (type) {
            case 'givenExpr':
                return '#37464F';
            case 'op':
                return '#718FA2';
            case 'fct':
                return '#884444';
            case 'angle':
                return '#444488';
            default:
                return '#37464F';
        }
    }

    return <>
    <Center mt={5}>
          <Box position={'relative'} fontSize={'lg'} sx={{ userSelect: 'none' }}>
            <Box height={'40px'} />

            {/* Inclined plane */}
            <Box height={'300px'} width={'100%'} position={'relative'}>
              <Image draggable={false} cursor={'default'} src={box_pic} alt="box" height={'300px'} />
              {/* Forces */}
              {pictureBoxes.map((pictureBox, index) => (
                <Droppable 
                  key={index} 
                  id={pictureBox.id}
                  style={{
                    top: pictureBox.offsetY+'%',
                    left: pictureBox.offsetX+'%',
                    bottom: pictureBox.offsetBottom+'%',
                    right: pictureBox.offsetRight+'%',
                  }}
                >
                    {[8,7,6,5].includes(pictureBoxes.length - index + 1) && 
                        <Text cursor={'default'} position={'absolute'} left={'-15px'} fontSize={'xs'} fontWeight={700}>{(pictureBoxes.length - index)+1}</Text>
                    }
                    {[1,3,4].includes(pictureBoxes.length - index + 1) && 
                        <Text cursor={'default'} position={'absolute'} right={'-15px'} fontSize={'xs'} fontWeight={700}>{(pictureBoxes.length - index)+1}</Text>
                    }
                    {(pictureBoxes.length - index + 1) == 2 &&
                        <Text cursor={'default'} position={'absolute'} top={'-20px'} fontSize={'xs'} fontWeight={700}>{(pictureBoxes.length - index)+1}</Text>
                    }
                    {index == 0 &&
                        <Text cursor={'default'} position={'absolute'} right={'-15px'} fontSize={'xs'} fontWeight={700}>{1}</Text>
                    }
                    <Latex>{'$'+pictureBox.value+'$'}</Latex>
                </Droppable>
              ))}
            </Box>

            {/* Angles */}
            <Box position={'absolute'} fontSize={'2xl'} bottom={'22px'} left={'20px'} color={'white'}>
              <Latex>{'$\\Phi$'}</Latex>
            </Box>
            <Box position={'absolute'} fontSize={'2xl'} bottom={'5px'} left={'100px'} color={'white'}>
              <Latex>{'$\\theta$'}</Latex>
            </Box>

            <Box position={'absolute'} fontSize={'lg'} top={'101px'} right={'49px'} color={'#485842'}>
              <Latex>{'$x$'}</Latex>
            </Box>
            <Box position={'absolute'} fontSize={'lg'} top={'45px'} right={'23px'} color={'#485842'}>
              <Latex>{'$y$'}</Latex>
            </Box>
          </Box>
    </Center>

    <Divider borderColor={'#52656D'} borderWidth={'2px'} borderRadius={'90'} mt={5} mb={2} />

    <Box mt={4} mb={2} textAlign={'justify'}>
        <Text fontSize={'2xl'} color={'#52656D'} fontWeight={800} mb={2}>Réponse précédente</Text>
        <Text fontSize={'md'} color={'white'} fontWeight={600}>
        <span style={{fontWeight:800}}>Forme vectorielle de la 2e loi de Newton :</span> <Latex>{'$'+answerStep2.map(
            (answer) => answer.value
        ).join('')+'$'}</Latex>
        </Text>
        {answerStep2.length == 0 && <Text fontSize={'md'} color={'white'} fontWeight={600}>Réponse vide...</Text>}
    </Box>

    <Divider borderColor={'#52656D'} borderWidth={'2px'} borderRadius={'90'} mt={5} mb={2} />

    <Box mt={4} mb={5} textAlign={'justify'}>
        <Text fontSize={'2xl'} color={'#52656D'} fontWeight={800} mb={2}>Étape 4 : Lois de Newton - projection sur l'axe y</Text>
        <Text fontSize={'md'} color={'white'} fontWeight={600}>
        Maintenant, projette cette relation vectorielle sur l'axe y (perpendiculaire au plan incliné).
        Pour ce faire, glisse les forces, opérateurs, fonctions, et constantes dans le cadre. Pour supprimer un élément, clique dessus.
        </Text>

        <Text fontSize={'md'} color={'white'} fontWeight={600}>
        <span style={{fontWeight:800}}>Attention :</span> ta réponse doit se terminer par "<Latex>=0</Latex>".
        </Text>
    </Box>

    <DndContext onDragEnd={onDragEnd}>
        <HStack spacing={2}>
            {variables.map((variable, index) => (
                <Draggable key={index} id={variable.id}>
                  <Box
                      sx={{ userSelect: 'none' }}
                      bgColor={'#131F24'}
                      className='hvr-grow'
                      cursor={'grab'}
                      onMouseDown={(e) => e.currentTarget.style.cursor = 'grabbing'}
                      onMouseUp={(e) => e.currentTarget.style.cursor = 'grab'}
                      border={'2px solid #37464F'}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      color={'white'} width={'70px'} height={'42px'} p={2} pt={1} pb={1} textAlign={'center'} rounded={'lg'}>
                      <Latex>{'$'+variable.value+'$'}</Latex>
                  </Box>
                </Draggable>
            ))}
        </HStack>

        <HStack mt={2} spacing={2}>
            {operators.map((variable, index) => (
                <Draggable key={index} id={variable.id}>
                <Box 
                    zIndex={1000}
                    sx={{ userSelect: 'none' }}
                    bgColor={'#131F24'}
                    className='hvr-grow'
                    cursor={'grab'}
                    onMouseDown={(e) => e.currentTarget.style.cursor = 'grabbing'}
                    onMouseUp={(e) => e.currentTarget.style.cursor = 'grab'}
                    border={'2px solid #718FA2'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    color={'white'} width={'70px'} height={'42px'} p={2} pt={1} pb={1} textAlign={'center'} rounded={'lg'}>
                    <Latex>{'$'+variable.value+'$'}</Latex>
                </Box>
                </Draggable>
            ))}
        </HStack>

        <HStack mt={2} spacing={2}>
            {functions.map((variable, index) => (
                <Draggable key={index} id={variable.id}>
                <Box 
                    sx={{ userSelect: 'none' }}
                    bgColor={'#131F24'}
                    className='hvr-grow'
                    cursor={'grab'}
                    onMouseDown={(e) => e.currentTarget.style.cursor = 'grabbing'}
                    onMouseUp={(e) => e.currentTarget.style.cursor = 'grab'}
                    border={'2px solid #884444'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    color={'white'} width={'70px'} height={'42px'} p={2} pt={1} pb={1} textAlign={'center'} rounded={'lg'}>
                    <Latex>{'$'+variable.value+'$'}</Latex>
                </Box>
                </Draggable>
            ))}
        </HStack>

        <HStack mt={2} spacing={2}>
            {constants.map((variable, index) => (
                <Draggable key={index} id={variable.id}>
                <Box 
                    sx={{ userSelect: 'none' }}
                    bgColor={'#131F24'}
                    className='hvr-grow'
                    cursor={'grab'}
                    onMouseDown={(e) => e.currentTarget.style.cursor = 'grabbing'}
                    onMouseUp={(e) => e.currentTarget.style.cursor = 'grab'}
                    border={'2px solid #444488'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    color={'white'} width={'70px'} height={'42px'} p={2} pt={1} pb={1} textAlign={'center'} rounded={'lg'}>
                    <Latex>{'$'+variable.value+'$'}</Latex>
                </Box>
                </Draggable>
            ))}
        </HStack>

        <AnswerBox id={'answer-box'}>
          <HStack
              pe={2}
              spacing={1}
              alignItems={'center'}
              height={'100%'}
              width={'100%'}>
              {answerBoxes.map((box, index) => (
                  <Box
                      className='hvr-grow'
                      cursor={'pointer'}
                      key={index}
                      sx={{ userSelect: 'none' }}
                      bgColor={'#131F24'}
                      border={'2px solid '+borderColorByType(box.type)}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      color={'white'}
                      width={'50px'}
                      height={'42px'}
                      p={2}
                      pt={1}
                      pb={1}
                      textAlign={'center'}
                      rounded={'lg'}
                      fontSize={'sm'}
                      onClick={() => deleteAnswerBox(index)}
                  >
                      <Latex>{'$'+box.value+'$'}</Latex>
              </Box>
              ))}
            </HStack>
        </AnswerBox>
    </DndContext>
  </>;
}
export default Step4;