import axios from "axios";
import Cookies from "js-cookie";

export const API_URL = process.env.REACT_APP_API_URL;
export const CAFE_URL = process.env.REACT_APP_CAFE_URL;

const API = axios.create({ baseURL: API_URL });

API.interceptors.request.use(
    (config) => {
        const jwtToken = Cookies.get('jwt_cafe');
        // If a JWT token exists, add it to the Authorization header
        if (jwtToken) {
            config.headers.Authorization = `Bearer ${jwtToken}`;
            return config;
        }
        else {
            // Trigger token event on document
            const event = new Event('TOKEN_ERROR');
            document.dispatchEvent(event);
            throw new Error("No token found");
        }
        
        return config;
    },
    // If no JWT token exists, redirect to login
    (error) => {
        console.log("[REQUEST] Error in request interceptor")
        console.log(error);
        return Promise.reject(error);
    }
);

API.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response != undefined && error.response.status === 401) {
            console.log("[RESPONSE] TOKEN ERROR");
            // Cookies.remove('jwt_cafe');
        }

        return Promise.reject(error);
    }
);

export default API;