import axios, { AxiosResponse } from "axios";
import API, { API_URL, CAFE_URL } from "./params";
import { IPhysicsReport, IUser } from "../types";

/*
 * POST request to the server to check the answer of a given fragment.
**/
export const saveProgress = async (answer:any): Promise<AxiosResponse> => {
    try {
        const fragments: AxiosResponse = await API.post(
            `${API_URL}/progress`,
             { answer:answer }
          );
        return fragments;
    } catch (error) {
        throw error;
    }
}

export const getProgress = async (): Promise<AxiosResponse> => {
    try {
        const fragments: AxiosResponse = await API.get(
            `${API_URL}/progress`
          );
        return fragments;
    } catch (error) {
        throw error;
    }
}

export const getFeedback = async (): Promise<AxiosResponse<IPhysicsReport>> => {
    try {
        const fragments: AxiosResponse<IPhysicsReport> = await API.get(
            `${API_URL}/feedback`
          );
        return fragments;
    } catch (error) {
        throw error;
    }
}

export const restart = async (): Promise<AxiosResponse> => {
    try {
        const fragments: AxiosResponse = await API.get(
            `${API_URL}/restart`
          );
        return fragments;
    } catch (error) {
        throw error;
    }
}

export const getUserData = async (): Promise<AxiosResponse<IUser>> => {
	try {
		const udata: AxiosResponse<IUser> = await API.get(
            `${API_URL}/user/data`
        );

        return udata;
	} catch (error) {
		throw error;
	}
};

/*
 * POST request to the server to check the answer of a given fragment.
**/
export const sendAnswerToCafe = async ( progress_id:string ): Promise<AxiosResponse> => {
    try {
        const feedback: AxiosResponse = await API.post(
            `${CAFE_URL}/physics/submit`,
             { 
                progress_id:progress_id
             }
          );
        return feedback;
    } catch (error) {
        throw error;
    }
 }