import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./fonts/DINRoundPro.ttf";
import "./fonts/DINRoundPro-Medi.ttf";
import "./fonts/DINRoundPro-Bold.ttf";
import "./fonts/DINRoundPro-Black.ttf";
import "./fonts/DINRoundPro-Light.ttf";
import "hover.css";
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Home';
import { UserProvider, useUser } from './UserContext';
import Cookies from 'js-cookie';
import Login from './Login';
import SetUser from './SetUser';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const App = () => {
//   return (
//     <ChakraProvider theme={theme}>
//       <BrowserRouter>
//         <Routes>
//           <Route index element={<Home />} />

//           {/* DEFAULT ROUTE */}
//           <Route path="*" element={<Home />} />
//         </Routes>
//       </BrowserRouter>
//     </ChakraProvider>
//   );
// };

const App = () => {
  const { user, setUser } = useUser();
  const [tokenError, setTokenError] = useState(true);

  useEffect(() => {
    const matToken = Cookies.get('jwt_cafe');
    if (matToken) {
      setTokenError(false);
    }
    else {
      setTokenError(true);
    }
    
    const tokenErrorListener = (e: any) => {
      console.log("TOKEN_ERROR received");
      setTokenError(true);
    }
    document.addEventListener("TOKEN_ERROR", tokenErrorListener);
}, []);

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
          {tokenError &&
            <Login />
          }
          {!tokenError && <>
            {user && <Routes>
              <Route index element={<Home />} />

              {/* DEFAULT ROUTE */}
              <Route path="*" element={<Home />} />
              </Routes>
            }
            {!user && <SetUser />}
          </>}
      </BrowserRouter>
    </ChakraProvider>
  );
};

const theme = extendTheme({
  // styles: {
  //   global: {
  //   },
  // },
  fonts: {
    body: `'Nunito', sans-serif`,
    heading: `'Nunito', sans-serif`,
  },
  colors: {
    gray: {
      900: '#212427'
    }
  }
});

root.render(
  <UserProvider initialUser={{rank:"user", firstname:"", lastname:"", matricule:""}}>
    <App />
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
