import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
} from '@chakra-ui/react';
import Latex from 'react-latex';
import box_pic from '../resources/Box.svg';
import { DndContext, useDraggable, useDroppable } from '@dnd-kit/core';

interface Step6Props {
    submitted: boolean;
    answerStep1: {id:number, content:string}[];
    answerStep2: {id:number,value:string,type:string,cafeval:string}[];
    answerStep3: {id:number,value:string,type:string,cafeval:string}[];
    answerStep4: {id:number,value:string,type:string,cafeval:string}[];
    answerStep5: {id:number,value:string,type:string,cafeval:string}[][];
}

const Step6: React.FunctionComponent<Step6Props> = ({submitted, answerStep1, answerStep2, answerStep3, answerStep4, answerStep5}) => {
    const [pictureBoxes, setPictureBoxes] = useState([
        {id: 1, value: '', offsetY: -15, offsetX: 55, offsetBottom: 0, offsetRight: 0},
        {id: 8, value: '', offsetY: -12, offsetX: 43, offsetBottom: 0, offsetRight: 0},
        {id: 7, value: '', offsetY: 35, offsetX: 29, offsetBottom: 0, offsetRight: 0},
        {id: 6, value: '', offsetY: 53, offsetX: 30, offsetBottom: 0, offsetRight: 0},
        {id: 5, value: '', offsetY: 86, offsetX: 55, offsetBottom: 0, offsetRight: 0},
        {id: 4, value: '', offsetY: 83, offsetX: 66.5, offsetBottom: 0, offsetRight: 0},
        {id: 3, value: '', offsetY: 37, offsetX: 80.5, offsetBottom: 0, offsetRight: 0},
        {id: 2, value: '', offsetY: 21, offsetX: 79, offsetBottom: 0, offsetRight: 0},
    ]);

    const [answerBoxes, setAnswerBoxes] = useState<{id:number, value:string, type:string, cafeval:string}[][]>([]);

    useEffect(() => {
        // Set the pictureBoxes values to the answer step 1
        let newPictureBoxes = [...pictureBoxes];
        answerStep1.forEach((value, index) => {
            //find the index of the answer box with the same id
            let i = newPictureBoxes.findIndex((box) => box.id === index+1);
            newPictureBoxes[i].value = value.content;
        });
        setPictureBoxes(newPictureBoxes);

        // Set the answer boxes to the answer step 5
        let newAnswerBoxes = [...answerBoxes];
        answerStep5.forEach((line, index) => {
            newAnswerBoxes.push(line);
        });
        
        setAnswerBoxes(newAnswerBoxes);
    }, [answerStep1]);

    function Droppable(props:any) {
        return (
        <Box 
            bgColor={'#131F24'}
            display={'flex'}
            alignItems={'center'}
            border={'2px solid #37464F'}
            justifyContent={'center'}
            height={'42px'}
            color={'white'} 
            width={'70px'} 
            pt={1} 
            textAlign={'center'} 
            rounded={'lg'}
            position={'absolute'}
            top={props.style.top}
            left={props.style.left}
            >
            {props.children}
            </Box>
        );
    }

    return <>
    <Center mt={5}>
          <Box position={'relative'} fontSize={'lg'} sx={{ userSelect: 'none' }}>
            <Box height={'40px'} />

            {/* Inclined plane */}
            <Box height={'300px'} width={'100%'} position={'relative'}>
              <Image draggable={false} cursor={'default'} src={box_pic} alt="box" height={'300px'} />
              {/* Forces */}
              {pictureBoxes.map((pictureBox, index) => (
                <Droppable 
                  key={index} 
                  id={pictureBox.id}
                  style={{
                    top: pictureBox.offsetY+'%',
                    left: pictureBox.offsetX+'%',
                    bottom: pictureBox.offsetBottom+'%',
                    right: pictureBox.offsetRight+'%',
                  }}
                >
                  {[8,7,6,5].includes(pictureBoxes.length - index + 1) && 
                    <Text cursor={'default'} position={'absolute'} left={'-15px'} fontSize={'xs'} fontWeight={700}>{(pictureBoxes.length - index)+1}</Text>
                  }
                  {[1,3,4].includes(pictureBoxes.length - index + 1) && 
                    <Text cursor={'default'} position={'absolute'} right={'-15px'} fontSize={'xs'} fontWeight={700}>{(pictureBoxes.length - index)+1}</Text>
                  }
                  {(pictureBoxes.length - index + 1) == 2 &&
                    <Text cursor={'default'} position={'absolute'} top={'-20px'} fontSize={'xs'} fontWeight={700}>{(pictureBoxes.length - index)+1}</Text>
                  }
                  {index == 0 &&
                    <Text cursor={'default'} position={'absolute'} right={'-15px'} fontSize={'xs'} fontWeight={700}>{1}</Text>
                  }
                  <Latex>{'$'+pictureBox.value+'$'}</Latex>
                </Droppable>
              ))}
            </Box>

            {/* Angles */}
            <Box position={'absolute'} fontSize={'2xl'} bottom={'22px'} left={'20px'} color={'white'}>
              <Latex>{'$\\Phi$'}</Latex>
            </Box>
            <Box position={'absolute'} fontSize={'2xl'} bottom={'5px'} left={'100px'} color={'white'}>
              <Latex>{'$\\theta$'}</Latex>
            </Box>

            <Box position={'absolute'} fontSize={'lg'} top={'101px'} right={'49px'} color={'#485842'}>
              <Latex>{'$x$'}</Latex>
            </Box>
            <Box position={'absolute'} fontSize={'lg'} top={'45px'} right={'23px'} color={'#485842'}>
              <Latex>{'$y$'}</Latex>
            </Box>
          </Box>
    </Center>

    <Box mt={2}>
        <Text fontWeight={800} color={'#52656D'} fontSize={'2xl'}>Résumé de tes réponses</Text>
        <Text fontSize={'xl'} color={'white'} fontWeight={700}>Forme vectorielle de la 2e loi de Newton</Text>
        <Text fontWeight={600}>
            <Latex>{'$'+answerStep2.map((answer) => answer.value).join('')+'$'}</Latex>
            {answerStep2.length == 0 && 'Aucune réponse'}
        </Text>

        <Text fontSize={'xl'} color={'white'} fontWeight={700}>Projection sur l'axe x :</Text>
        <Text fontWeight={600}>
            <Latex>
                {'$'+answerStep3.map((answer) => answer.value).join('')+'$'}
            </Latex>
            {answerStep3.length == 0 && 'Aucune réponse'}
        </Text>
        
        <Text fontSize={'xl'} color={'white'} fontWeight={700}>Projection sur l'axe y :</Text>
        <Text fontWeight={600}>
            <Latex>
                {'$'+answerStep4.map((answer) => answer.value).join('')+'$'}
            </Latex>
            {answerStep4.length == 0 && 'Aucune réponse'}
        </Text>

        <Text fontSize={'xl'} color={'white'} fontWeight={700}>Expression de la force de frottement :</Text>
        {answerStep5.length > 0 && answerStep5.every((line) => line.length == 0) && <Text fontWeight={600}>Aucune réponse</Text>}
        
        {!(answerStep5.length > 0 && answerStep5.every((line) => line.length == 0)) && answerStep5.map((line, index) => (
            <Box key={index}>{line.length == 0 ? 'Ligne vide' :<Latex>{'$'+line.map((answer) => answer.value).join('')+'$'}</Latex>}</Box>
        ))}
        
        {answerStep5.length == 0 && <Text fontWeight={600}>Aucune réponse</Text>}
    </Box>

    {!submitted && 
    <Box mt={4}>
      <Text fontWeight={600}>
        Vérifie tes réponses et clique sur le bouton pour les soumettre. Elles seront envoyées à un correcteur automatique, et un feedback te sera donné après quelques secondes. Le contenu du schéma sera également corrigé.
      </Text>
    </Box>
    }
    
  </>;
}
export default Step6;